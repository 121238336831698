import { Component, OnInit, NgZone, ApplicationRef } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from 'src/app/classes/globals.class';
import { Title, Meta } from '@angular/platform-browser';

declare let $: any;
declare let swal: any;
declare let toastr: any;

@Component({
  selector: 'app-actividades',
  templateUrl: './actividades.component.html'
})
export class ActividadesComponent implements OnInit {
  public titulo:string = '';
  public id_colaborador:string = this.globals.GetQueryString('keycol2022034123tkaHTplk');
  public actividades = [];
  public disponible:boolean = true;

  constructor(
    public title: Title,
    public meta: Meta,
    public ngZone: NgZone,
    public router: Router,
    public appref: ApplicationRef,
    public globals: Globals
  ) { }

  ngOnInit(): void {
    if (!this.id_colaborador || this.id_colaborador == '') {
      return;
    }
    this.title.setTitle(this.titulo);
    this.meta.updateTag({name: 'description', content: this.titulo});
    if (this.globals.GetQueryString('lang')) {
      this.globals.idioma = this.globals.GetQueryString('lang');
      if (!this.globals.idioma || this.globals.idioma == '') this.globals.idioma = 'es';
      this.titulo = this.globals.Literal('Actividades');
      this.title.setTitle(this.titulo);
    } else {
      this.globals.idioma = localStorage.getItem('idioma');
      if (!this.globals.idioma || this.globals.idioma == '') this.globals.idioma = 'es';
      this.titulo = this.globals.Literal('Actividades');
      this.title.setTitle(this.titulo);
    }
    this.globals.Api('/act-actividades-publico', {idioma: this.globals.idioma, id_colaborador: this.id_colaborador}).subscribe(data => {
      if (!data || !data.length) {
        this.actividades = [];return;
      }
      // this.actividades = JSON.parse(data);
      this.actividades = data;
      console.log(data);
      this.disponible = false;
      for (let item of this.actividades) {
        if (item.disponible_vip) {
          this.disponible = true;
          break;
        }
      }
      this.appref.tick();
    });
  }
  IrActividad(item:any) {
    if (!item || !item.seo_url) return;
    if (item.pasado) {
      toastr.error('Esta actividad ya no está disponible para acceso reservado. Seleccione otra actividad', 'No disponible');
      return;
    }
    if (!item.disponible_vip) {
      this.ngZone.run(() => this.router.navigateByUrl('/actividad/'+item.seo_url)).then();
      return;
    }
    this.ngZone.run(() => this.router.navigateByUrl('/actividad/'+item.seo_url+'?keycol2022034123tkaHTplk='+this.id_colaborador)).then();
  }

}
