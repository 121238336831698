<div id="wrapper">
    <app-menu></app-menu>

    <div id="page-wrapper" class="gray-bg dashbard-1">
      <app-header></app-header>

      <div class="row wrapper border-bottom white-bg page-heading">
        <div class="col-md-8 col-sm-6 col-xs-12">
            <h2>
                <i class="fa fa-cogs"></i>
                Configuraci&oacute;n App
            </h2>
            <ol class="breadcrumb m-l-10">
                <li class="breadcrumb-item">
                    <a routerLink="/dashboard">Inicio</a>
                </li>
                <li class="breadcrumb-item active">
                    <strong>Configuraci&oacute;n App</strong>
                </li>
            </ol>
        </div>
        <div class="col-md-4 col-sm-6 col-xs-12 col-botones">
            <a class="btn btn-sm btn-default" routerLink="/dashboard">Cancelar</a>
            <a id="btnGuardar" class="btn btn-sm btn-primary m-l-20" (click)="Guardar()">Guardar</a>
        </div>
      </div>
      <div class="row animated fadeInRight">
        <div class="tabs-container">
          <ul class="nav nav-tabs" role="tablist">
              <li><a class="nav-link active" data-toggle="tab" href="#tab-general"><i class="icofont-settings-alt"></i> General</a></li>
              <li><a class="nav-link" data-toggle="tab" href="#tab-correo"><i class="icofont-envelope"></i> Correo</a></li>
              <!-- <li><a class="nav-link" data-toggle="tab" href="#tab-pago"><i class="icofont-credit-card"></i> Pago</a></li> -->
              <li><a class="nav-link" data-toggle="tab" href="#tab-personalizacion"><i class="icofont-paint"></i> Personalizaci&oacute;n</a></li>
              <li><a class="nav-link" data-toggle="tab" href="#tab-db"><i class="icofont-database"></i> Base de datos</a></li>
              <li><a class="nav-link" data-toggle="tab" href="#tab-wordpress"><i class="icofont-brand-wordpress"></i> WordPress</a></li>
          </ul>
          <div class="tab-content">
              <div role="tabpanel" id="tab-general" class="tab-pane active">
                  <div class="panel-body">
                    <div class="row">
                        <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                          <div class="row">
                            <!-- <div class="col-md-6 col-sm-12">
                              <div class="form-group">
                                    <label>Tipo App</label>
                                    <input type="text" class="form-control" [(ngModel)]="data.tipoapp" maxlength="50">
                              </div>
                            </div> -->
                            <div class="col-md-6 col-sm-12">
                              <div class="form-group">
                                    <label>Nombre</label>
                                    <input type="text" class="form-control" [(ngModel)]="data.nombre" maxlength="80">
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12" *ngIf="data && data.monfrague">

                          <div class="ibox border">
                            <div class="ibox-title block font-bold" style="background-color:#94C11E;color:#FFF">Monfragüe</div>
                            <div class="ibox-content">

                              <div class="form-group">
                                  <label>Alias email administrador</label>
                                  <input type="text" class="form-control" [(ngModel)]="data.monfrague.email_admin_alias" maxlength="80">
                              </div>
                              <div class="form-group">
                                  <label>Email administrador 1</label>
                                  <input type="email" class="form-control" [(ngModel)]="data.monfrague.email_admin1" maxlength="80">
                              </div>
                              <div class="form-group">
                                  <label>Email administrador 2</label>
                                  <input type="email" class="form-control" [(ngModel)]="data.monfrague.email_admin2" maxlength="80">
                              </div>
                              <div class="form-group">
                                  <label>Email administrador 3</label>
                                  <input type="email" class="form-control" [(ngModel)]="data.monfrague.email_admin3" maxlength="80">
                              </div>
                              <hr>
                              <h2 class="font-14 font-bold"><i class="fa fa-whatsapp"></i> Notificaciones WhatsApp</h2>
                              <div class="form-group">
                                  <label>Facebook ID</label>
                                  <input type="text" class="form-control" [(ngModel)]="data.monfrague.whatsapp_fbid" maxlength="80">
                              </div>
                              <div class="form-group">
                                  <label>Facebook Access Token</label>
                                  <input type="text" class="form-control" [(ngModel)]="data.monfrague.whatsapp_access_token" maxlength="400">
                              </div>

                            </div>
                          </div>

                        </div>
                        <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12" *ngIf="data && data.tajo">
                          <div class="ibox border">
                            <div class="ibox-title block font-bold" style="background-color:#5AB0E3;color:#FFF">Tajo / Tejo</div>
                            <div class="ibox-content">

                              <div class="form-group">
                                  <label>Alias email administrador</label>
                                  <input type="text" class="form-control" [(ngModel)]="data.tajo.email_admin_alias" maxlength="80">
                              </div>
                              <div class="form-group">
                                  <label>Email administrador 1</label>
                                  <input type="email" class="form-control" [(ngModel)]="data.tajo.email_admin1" maxlength="80">
                              </div>
                              <div class="form-group">
                                  <label>Email administrador 2</label>
                                  <input type="email" class="form-control" [(ngModel)]="data.tajo.email_admin2" maxlength="80">
                              </div>
                              <div class="form-group">
                                  <label>Email administrador 3</label>
                                  <input type="email" class="form-control" [(ngModel)]="data.tajo.email_admin3" maxlength="80">
                              </div>
                              <hr>
                              <h2 class="font-14 font-bold"><i class="fa fa-whatsapp"></i> Notificaciones WhatsApp</h2>
                              <div class="form-group">
                                  <label>Facebook ID</label>
                                  <input type="text" class="form-control" [(ngModel)]="data.tajo.whatsapp_fbid" maxlength="80">
                              </div>
                              <div class="form-group">
                                  <label>Facebook Access Token</label>
                                  <input type="text" class="form-control" [(ngModel)]="data.tajo.whatsapp_access_token" maxlength="400">
                              </div>

                            </div>
                          </div>
                        </div>
                    </div>
                  </div>
              </div>
              <div role="tabpanel" id="tab-correo" class="tab-pane">
                <div class="panel-body">
                  <div class="row">
                      <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                          <div class="block">
                              <div class="block-title"><h2>Correo salida</h2></div>
                              <div class="row">
                                  <div class="col-md-6">
                                      <div class="form-group">
                                          <label>Host</label>
                                          <input type="text" class="form-control" [(ngModel)]="data.emailconf_host" maxlength="80">
                                      </div>
                                  </div>
                                  <div class="col-md-3">
                                      <div class="form-group">
                                          <label>Port</label>
                                          <input type="number" class="form-control" [(ngModel)]="data.emailconf_port" maxlength="12">
                                      </div>
                                  </div>
                                  <div class="col-md-3">
                                      <div class="form-group">
                                          <label>SSL</label>
                                          <select class="form-control" [(ngModel)]="data.emailconf_ssl">
                                          <option value="1">Sí</option>
                                          <option value="0" selected>No</option>
                                          </select>
                                      </div>
                                  </div>
                              </div>
                              <div class="form-group">
                                  <label>Email para env&iacute;os</label>
                                  <input type="email" class="form-control" [(ngModel)]="data.emailconf_email" maxlength="80">
                              </div>
                              <div class="form-group">
                                  <label>Contraseña de email para env&iacute;os</label>
                                  <input type="text" class="form-control" [(ngModel)]="data.emailconf_pass" maxlength="80">
                              </div>
                              <div class="form-group">
                                  <label>Alias de email para env&iacute;os</label>
                                  <input type="text" class="form-control" [(ngModel)]="data.emailconf_alias" maxlength="80">
                              </div>
                          </div>
                      </div>
                      <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                          <div class="block">
                              <div class="block-title"><h2>Correo administrador</h2></div>
                              <div class="form-group">
                                  <label>Alias email administrador</label>
                                  <input type="text" class="form-control" [(ngModel)]="data.emailconf_emailadmin_alias" maxlength="80">
                              </div>
                              <div class="form-group">
                                  <label>Email administrador 1</label>
                                  <input type="email" class="form-control" [(ngModel)]="data.emailconf_emailadmin1" maxlength="80">
                              </div>
                              <div class="form-group">
                                  <label>Email administrador 2</label>
                                  <input type="email" class="form-control" [(ngModel)]="data.emailconf_emailadmin2" maxlength="80">
                              </div>
                              <div class="form-group">
                                  <label>Email administrador 3</label>
                                  <input type="email" class="form-control" [(ngModel)]="data.emailconf_emailadmin3" maxlength="80">
                              </div>
                          </div>
                          <div>
                              <a class="btn btn-default btn-block m-b-20" (click)="EmailTest()"><i class="icofont-envelope"></i> Enviar Test</a>
                          </div>
                      </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                      <div class="row">
                        <div class="col-6">
                          <h3>Plantillas email / WhatsApp</h3>
                        </div>
                        <div class="col-6 text-right">
                          <a class="btn btn-primary" (click)="AddPlantilla()"><i class="fa fa-plus"></i> Añadir plantilla</a>
                        </div>
                      </div>
                      <div class="table-responsive table-grid ibox-content padding-0">
                        <table class="table table-hover">
                            <thead>
                              <tr>
                                <th order="asunto">Plantilla</th>
                                <th style="width:50px" order="idioma">Idioma</th>
                                <th style="width:100px" order="tipo">Tipo</th>
                                <th style="width:100px" order="tablas">Tablas</th>
                                <th style="width:50px">Previsualizar</th>
                                <th style="width:50px">Clonar</th>
                                <th style="width:50px">Editar</th>
                                <th style="width:50px">Borrar</th>
                              </tr>
                            </thead>
                            <tbody>
                            <tr *ngFor="let item of plantillas" class="cursor">
                              <td class="font-bold text-primary" (click)="EditPlantilla(item)" style="line-height:1.2;">
                                <div>{{item.asunto}}</div>
                                <small *ngIf="item.slug" class="font-italic">{{item.slug}}</small>
                              </td>
                              <td>{{item.idioma}}</td>
                              <td>
                                <i class="fa fa-envelope text-info" *ngIf="item.tipo == 'email'"></i>
                                <i class="fa fa-whatsapp text-success" *ngIf="item.tipo == 'whatsapp'"></i>
                                {{item.tipo}}
                              </td>
                              <td class="breaktd"><span *ngIf="item.tablas">{{item.tablas}}</span></td>
                              <td class="text-center btntd"><a class="btn btn-default btn-xs" (click)="VerPlantilla(item)"><i class="fa fa-eye"></i></a></td>
                              <td class="text-center btntd"><a class="btn btn-default btn-xs" (click)="ClonarPlantilla(item)"><i class="fa fa-clone"></i></a></td>
                              <td class="text-center btntd"><a class="btn btn-primary btn-xs" (click)="EditPlantilla(item)"><i class="fa fa-pencil text-white"></i></a></td>
                              <td class="text-center btntd"><a class="btn btn-danger btn-xs" (click)="DelPlantilla(item)"><i class="fa fa-trash text-white"></i></a></td>
                            </tr>
                            </tbody>
                        </table>
                      </div>

                    </div>
                    <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12" *ngIf="data.logs_email && data.logs_email.length">
                      <h3>Logs email</h3>
                      <div class="logs">
                        <div *ngFor="let item of data.logs_email">
                          <div>Fecha: {{item.fecha}}</div>
                          <div>{{item.log}} {{item.exception}}</div>
                          <div>################################</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div role="tabpanel" id="tab-pago" class="tab-pane">
                  <div class="panel-body">
                    <div class="row">
                      <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                          <div class="ibox border">
                            <div class="ibox-title"><i class="fa fa-credit-card"></i> Pago Redsys</div>
                            <div class="ibox-content">
                              <div class="form-group">
                                  <label>Nº Comercio</label>
                                  <input type="number" class="form-control" [(ngModel)]="data.redsys_numcomercio" maxlength="12">
                              </div>
                              <div class="form-group">
                                  <label>Nombre de comercio</label>
                                  <input type="text" class="form-control" [(ngModel)]="data.redsys_nombre_comercio" maxlength="80">
                              </div>
                              <div class="form-group">
                                  <label>Cifrado SHA-256</label>
                                  <input type="text" class="form-control" [(ngModel)]="data.redsys_cifrado" maxlength="300">
                              </div>
                              <div class="form-group">
                                  <label>Nº Moneda</label>
                                  <input type="number" class="form-control" [(ngModel)]="data.redsys_nummoneda" maxlength="12">
                              </div>
                              <div class="form-group">
                                  <label>Entorno de pruebas</label>
                                  <select class="form-control" [(ngModel)]="data.redsys_entornopruebas">
                                    <option value="1" selected>Sí</option>
                                    <option value="0">No</option>
                                  </select>
                              </div>
                            </div>
                          </div>
                      </div>
                    </div>
                  </div>
              </div>
              <div role="tabpanel" id="tab-personalizacion" class="tab-pane">
                  <div class="panel-body">
                    <label>CSS Personalizado</label>
                    <div id="txtcustomcss" [innerHTML]="data.customcss" style="height:300px;"></div>
                  </div>
              </div>
              <div role="tabpanel" id="tab-db" class="tab-pane">
                  <div class="panel-body">
                    <div class="row">
                      <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
                        <div class="ibox border" *ngIf="globals.config && globals.config.tipoapp == 'actividades'">
                          <div class="ibox-title"><i class="icofont-database"></i> Exportar Base Datos</div>
                          <div class="ibox-content">
                            <a class="btn btn-default btn-block" (click)="ExportarDB()"><i class="icofont-database"></i> Exportar</a>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
                        <div class="ibox border" *ngIf="globals.config && globals.config.tipoapp == 'actividades'">
                          <div class="ibox-title"><i class="icofont-file-excel"></i> Exportar a excel</div>
                          <div class="ibox-content">
                            <a class="btn btn-default btn-block" (click)="ExportarExcel('personas-inscritas')"><i class="icofont-file-excel"></i> Excel asistentes</a>
                            <a class="btn btn-default btn-block" (click)="ExportarExcel('personas-inscritas', 'confirmadas-espera')"><i class="icofont-file-excel"></i> Excel confirmadas + espera</a>
                            <hr>
                            <a class="btn btn-default btn-block" (click)="ExportarDatosGenerales()"><i class="icofont-file-excel"></i> Datos generales</a>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
                        <div class="ibox border" *ngIf="globals.config && globals.config.tipoapp == 'actividades'">
                          <div class="ibox-title"><i class="icofont-file-excel"></i> Sincro Diputación</div>
                          <div class="ibox-content">
                            <a class="btn btn-default btn-block" (click)="SincroDip()"><i class="icofont-exchange"></i> Sincro Diputación</a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr>
                    <label>Ejecutar SQL Query <a class="btn btn-default" (click)="EjecutarSQL()"><i class="fa fa-play"></i></a></label>
                    <div id="txtsql" style="height:100px;"></div>
                    <div id="txtresultsql" class="m-t-10" style="height:150px;"></div>
                    <hr>
                    <label>Logs DB
                      <a class="btn btn-default m-l-10" (click)="CargarLogs()"><i class="fa fa-refresh"></i></a>
                      <a class="btn btn-default m-l-10" (click)="BorrarLogs()"><i class="fa fa-trash"></i></a>
                    </label>
                    <div id="txtlogsdb" style="height:300px;"></div>
                  </div>
              </div>
              <div role="tabpanel" id="tab-wordpress" class="tab-pane">
                <div class="panel-body">
                  <div class="row">
                      <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                          <div class="block">
                              <div class="block-title"><h2>Conexión base de datos WordPress</h2></div>
                              <div class="form-group">
                                  <label>Conectar</label>
                                  <label class="switch switch-success switch-sm m-l-10">
                                      <input type="checkbox" [(ngModel)]="data.wp_db_conectar"><span></span>
                                  </label>
                              </div>
                              <div class="form-group">
                                  <label>Host</label>
                                  <input type="email" class="form-control" [(ngModel)]="data.wp_db_host" maxlength="200">
                              </div>
                              <div class="form-group">
                                  <label>Nombre</label>
                                  <input type="text" class="form-control" [(ngModel)]="data.wp_db_name" maxlength="50">
                              </div>
                              <div class="form-group">
                                  <label>Usuario</label>
                                  <input type="text" class="form-control" [(ngModel)]="data.wp_db_user" maxlength="50">
                              </div>
                              <div class="form-group">
                                  <label>Contrase&ntilde;a</label>
                                  <input type="text" class="form-control" [(ngModel)]="data.wp_db_pass" maxlength="50">
                              </div>
                              <div class="form-group">
                                  <label>Prefijo tablas</label>
                                  <input type="text" class="form-control" [(ngModel)]="data.wp_db_prefix" maxlength="50">
                              </div>
                              <div class="form-group">
                                  <a id="btn-wordpress-conexion" class="btn btn-default btn-block cursor" (click)="TestWPConexion()">
                                      Probar conexión
                                  </a>
                              </div>
                          </div>
                      </div>
                      <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                          <ul class="list-group" *ngIf="wp_tablas && wp_tablas.length">
                              <li class="list-group-item" *ngFor="let item of wp_tablas">{{item.table_name}}</li>
                          </ul>
                      </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>

      <app-footer></app-footer>
    </div>

</div>

<div class="modal fade" id="modal-edit-plantilla" tabindex="-1" role="dialog"  aria-hidden="true">
  <div class="modal-dialog modal-lg-custom">
      <div class="modal-content">
          <div class="modal-header">
              <h4 class="modal-title font-20 text-primary">{{dataitem.title}}</h4>
              <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">&times;</span><span class="sr-only">Cerrar</span></button>
          </div>
          <div class="modal-body" style="background-color:#fafafa;">

            <div class="row">
                <div class="col-md-6 col-xs-12">
                  <div class="form-group">
                    <label>Asunto <span class="text-danger">*</span></label>
                    <input type="text" class="form-control" [(ngModel)]="dataitem.asunto" maxlength="80">
                  </div>
                </div>
                <div class="col-md-3 col-xs-12">
                  <div class="form-group">
                    <label>Tipo <span class="text-danger">*</span></label>
                    <select class="form-control" [(ngModel)]="dataitem.tipo">
                        <option value="email" selected>Email</option>
                        <option value="whatsapp" selected>WhatsApp</option>
                    </select>
                  </div>
                </div>
                <div class="col-md-3 col-xs-12">
                  <div class="form-group">
                    <label>Idioma <span class="text-danger">*</span></label>
                    <select class="form-control" [(ngModel)]="dataitem.idioma">
                        <option value="es" selected>Español</option>
                        <option value="en" selected>Inglés</option>
                        <option value="fr" selected>Francés</option>
                        <option value="pt" selected>Portugués</option>
                    </select>
                  </div>
                </div>
            </div>
            <div class="form-group">
              <label>Tablas</label>
              <input type="text" class="form-control" [(ngModel)]="dataitem.tablas" maxlength="300">
            </div>

            <div class="form-group {{dataitem.tipo == 'email' ? null : 'hidden'}}">
                <label>Plantilla <span class="text-danger">*</span></label>
                <div id="plantilla"></div>
            </div>
            <div class="row {{dataitem.tipo == 'whatsapp' ? null : 'hidden'}}">
              <div class="col-md-12 col-xs-12">
                <div class="form-group">
                  <label>Plantilla <span class="text-danger">*</span></label>
                  <textarea class="form-control" rows="12" [(ngModel)]="dataitem.plantilla"></textarea>
                </div>
              </div>
            </div>

          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-white" data-dismiss="modal">Cancelar</button>
            <button id="btn-guardar-plantilla" type="button" class="btn btn-primary" (click)="GuardarPlantilla()">Guardar</button>
          </div>
      </div>
  </div>
</div>