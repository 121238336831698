<div class="top-navigation">
    <div id="wrapper">
      <div id="page-wrapper" class="gray-bg">

        <app-cabecera></app-cabecera>

        <ng-container *ngIf="!enviado">
          <div class="wrapper wrapper-content animated fadeInDown">
            <div class="container">
              <h2 class="mb-4">Únete como Colaborador en el Mes de la Reserva de la Biosfera {{anio}}</h2>

              <div class="row">
                <div class="col-md-4 col-xs-12">
                  <div class="form-group">
                    <label>Logo de tu negocio <span class="text-danger">*</span></label>
                    <div *ngIf="!data.imagen || data.imagen == ''" class="cursor text-center m-t-10 no-image" (click)="SelecImagen()">
                      <i class="icofont-camera-alt"></i>
                    </div>
                    <div class="img-preview-container cursor relative" (click)="SelecImagen()" *ngIf="data.imagen && data.imagen != ''">
                      <img [src]="data.imagen">
                      <button class="btn btn-danger absolute" (click)="BorrarImagen($event)" style="top:0;right:0"><i class="fa fa-trash"></i></button>
                    </div>
                  </div>
                </div>
                <div class="col-md-8 col-xs-12">
                  <div class="row">
                    <div class="col-md-6 col-xs-12">
                        <div class="form-group">
                          <label>Nombre de tu negocio <span class="text-danger">*</span></label>
                          <input type="text" class="form-control" [(ngModel)]="data.empresa" maxlength="80">
                        </div>
                    </div>
                    <div class="col-md-6 col-xs-12">
                      <div class="form-group">
                        <label>Territorio <span class="text-danger">*</span></label>
                        <select class="form-control" [(ngModel)]="data.id_ubicacion">
                          <option value="1">Monfragüe</option>
                          <option value="2">Tajo - Tejo Internacional</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6 col-xs-12">
                        <div class="form-group">
                          <label>Nombre persona de contacto <span class="text-danger">*</span></label>
                          <input type="text" class="form-control" [(ngModel)]="data.nombre" maxlength="80">
                        </div>
                    </div>
                    <div class="col-md-6 col-xs-12">
                        <div class="form-group">
                          <label>Apellidos persona de contacto <span class="text-danger">*</span></label>
                          <input type="text" class="form-control" [(ngModel)]="data.apellidos" maxlength="80">
                        </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-4 col-xs-12">
                        <div class="form-group">
                            <label>C&oacute;digo postal <span class="text-danger">*</span></label>
                            <input type="text" class="form-control" [(ngModel)]="data.codpostal" maxlength="8" autocomplete="off"
                                (change)="DatosLocalidad()">
                        </div>
                    </div>
                    <div class="col-sm-4 col-xs-12">
                      <div class="form-group">
                          <label>Localidad <span class="text-danger">*</span></label>
                          <input type="text" class="form-control" [(ngModel)]="data.localidad" maxlength="80" autocomplete="off">
                      </div>
                    </div>
                    <div class="col-sm-4 col-xs-12">
                        <div class="form-group">
                            <label>Provincia <span class="text-danger">*</span></label>
                            <input type="text" class="form-control" [(ngModel)]="data.provincia" maxlength="80" autocomplete="off">
                        </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6 col-xs-12">
                        <div class="form-group">
                          <label>Teléfono <span class="text-danger">*</span></label>
                          <input type="tel" class="form-control" [(ngModel)]="data.telefono" maxlength="20">
                        </div>
                    </div>
                    <div class="col-md-6 col-xs-12">
                        <div class="form-group">
                          <label>Email <span class="text-danger">*</span></label>
                          <input type="email" class="form-control" [(ngModel)]="data.email" maxlength="80">
                        </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label>Web</label>
                    <input type="url" class="form-control" [(ngModel)]="data.web" maxlength="200">
                  </div>
                  <div class="form-group">
                    <label>Actividades o servicios que realizas</label>
                    <textarea class="form-control" [(ngModel)]="data.servicios" maxlength="2000" rows="4"></textarea>
                  </div>
                  <div class="text-right">
                    <a id="btn-enviar-solicitud" class="btn btn-primary" (click)="EnviarSolicitud()">Enviar solicitud <i class="fa fa-send"></i></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="enviado">
          <div class="wrapper wrapper-content animated fadeInDown">
            <div class="container">
              <h2 class="mb-4">Datos enviados correctamente</h2>
              <div class="font-18">
                <p>Hemos recibido correctamente tus datos. En los próximos días, revisaremos la información proporcionada</p>
                <p>
                  Si tienes alguna pregunta o necesitas más información, no dudes en contactarnos.
                </p>
                <p class="font-bold">¡Gracias por tu colaboración!</p>
              </div>
            </div>
          </div>
        </ng-container>

      </div>
    </div>
</div>