<div class="top-navigation">
    <div id="wrapper">
      <div id="page-wrapper" class="gray-bg" *ngIf="data">

        <app-cabecera [titulo]="titulo"></app-cabecera>
        
        <div class="wrapper wrapper-content animated fadeInDown" *ngIf="!reservado">
            <!-- <ul class="desktop-hidden-flex-2" style="list-style: none;justify-content: center;margin-top:-24px;">
                <li><a class="cursor" (click)="SetIdioma('es')" class="btn-flag m-r-10"><img src="assets/img/flags/32/Spain.png" alt=""></a></li>
                <li><a class="cursor" (click)="SetIdioma('pt')" class="btn-flag m-l-10"><img src="assets/img/flags/32/Portugal.png" alt=""></a></li>
            </ul> -->
          <div class="container actividad-detalles {{globals.CleanedString(data.ubicacion, '-')}}">
              <div class="row">
                  <div class="col-md-6 col-sm-12 col-xs-12">
                    <div class="ibox">
                        <div class="ibox-title">
                            {{globals.Literal('Detalles de la actividad')}}
                        </div>
                        <div class="ibox-content no-padding">
                            <div class="cartel-actividad {{globals.CleanedString(data.ubicacion, '-')}}" *ngIf="data.imagen">
                                <img id="img-actividad" [src]="data.imagen" alt="actividad" class="img-fluid" *ngIf="data.imagen">
                                <div class="fecha"><span>{{data.fecha_dia}}</span>{{data.fecha_mes}}</div>
                                <div class="badge-personas" *ngIf="globals.CleanedString(data.ubicacion, '-') == 'monfragüe'">
                                    <p><img src="assets/img/icon-user.png">{{data.aforo}} {{globals.Literal('personas')}}</p>
                                </div>
                                <div class="badge-personas" *ngIf="globals.CleanedString(data.ubicacion, '-') == 'tajo--tejo-internacional'">
                                    <p><img src="assets/img/icon-user-tajo.png">{{data.aforo}} {{globals.Literal('personas')}}</p>
                                </div>
                            </div>
                            <div class="p-l-15 p-r-15 p-b-20 m-t-20">
                                <h1 class="text-primary">{{data.titulo}}</h1>
                                <div [innerHTML]="data.descripcion"></div>
                                
                                <div class="row resumen-container m-t-40">
                                    <div class="col1 {{!data.material_necesario && !data.material_recomendado ? 'col-md-12' : 'col-md-6'}} col-sm-12">
                                        <!-- <div class="fecha"><i class="fa fa-calendar"></i> {{globals.Literal('Fecha')}}: <strong>{{data.fecha_mes}} {{data.fecha_dia}}</strong></div> -->
                                        <div *ngIf="data.organizador" class="organizador">{{globals.Literal('Organiza')}}: <strong>{{data.organizador}}</strong></div>
                                        <ng-container *ngIf="!data.turnos">
                                            <div *ngIf="data.hora_inicio" class="hora-inicio"><i class="fa fa-clock-o"></i> {{globals.Literal('Hora inicio')}}: <span>{{data.hora_inicio}}h</span></div>
                                            <div *ngIf="data.hora_fin" class="hora-fin"><i class="fa fa-clock-o"></i> {{globals.Literal('Hora fin')}}: <span>{{data.hora_fin}}h</span></div>
                                        </ng-container>
                                        <ng-container *ngIf="data.turnos">
                                            <div *ngFor="let item of data.turnos.split(',');let i = index" class="hora-inicio">
                                                <span class="pull-right font-12" style="font-weight:normal;" *ngIf="AforoTurno(item).completo"><i class="fa fa-exclamation-triangle" style="font-size:11px;margin-right:5px;"></i> {{globals.Literal('Aforo completo')}}</span>
                                                <i class="fa fa-clock-o"></i> Turno {{i+1}}: <span class="m-l-10">{{item}}</span><br>
                                                 
                                                <!-- <div *ngIf="AforoTurno(item).aforo_completado != null && data.disponible"><span><b>{{AforoTurno(item).aforo_completado}}</b> de </span>{{AforoTurno(item).aforo}} {{globals.Literal('personas')}}</div>
                                                <div class="progress m-b-30" *ngIf="AforoTurno(item).aforo_completado != null && data.disponible">
                                                    <div class="progress-bar progress-primary" style="width:{{AforoTurno(item).aforo_completado*100/AforoTurno(item).aforo}}%" role="progressbar" aria-valuemin="0" aria-valuemax="100"></div>
                                                </div> -->
                                            </div>
                                        </ng-container>
                                        <div class="aforo"><i class="fa fa-user"></i> {{globals.Literal('Aforo')}}: <strong>{{data.aforo}}</strong> {{globals.Literal('personas')}}</div>
                                        <ng-container *ngIf="!data.turnos">
                                            <span class="pull-right font-12" style="font-weight:normal;" *ngIf="data.aforo_completo"><i class="fa fa-exclamation-triangle" style="font-size:11px;margin-right:5px;"></i> {{globals.Literal('Aforo completo')}}</span>
                                            <!-- <div *ngIf="data.aforo_completado != null && data.disponible"><span><b>{{data.aforo_completado}}</b> de </span>{{data.aforo}} {{globals.Literal('personas')}}</div>
                                            <div class="progress m-b-30" *ngIf="data.aforo_completado != null && data.disponible">
                                                <div class="progress-bar progress-primary" style="width:{{data.aforo_completado*100/data.aforo}}%" role="progressbar" aria-valuemin="0" aria-valuemax="100"></div>
                                            </div> -->
                                        </ng-container>
                                        <div *ngIf="data.localidad" class="municipio"><i class="fa fa-map-marker"></i> {{globals.Literal('Municipio')}}: <strong>{{data.localidad}}</strong></div>
                                        <div *ngIf="data.municipio" class="municipio"><i class="fa fa-map-pin"></i> {{globals.Literal('Punto de encuentro')}}: <strong>{{data.municipio}}</strong></div>
                                    </div>
                                    <div class="col-md-6 col-sm-12">
                                        <div *ngIf="data.material_necesario" class="material-necesario"><div>{{globals.Literal('Material necesario')}}:</div>{{data.material_necesario}}</div>
                                        <div *ngIf="data.material_recomendado" class="material-recomendado"><div>{{globals.Literal('Material recomendado')}}:</div>{{data.material_recomendado}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                  </div>
                  <div class="col-md-6 col-sm-12 col-xs-12">
                        <ng-container *ngIf="!data.disponible">
                            <ng-container *ngIf="data.proximamente">
                                <div class="ibox border">
                                    <div class="ibox-title"><i class="fa fa-lock"></i> {{globals.Literal('Actividad cerrada')}}</div>
                                    <div class="ibox-content">
                                        <p class="font-16" [innerHTML]="globals.Literal('Mensaje actividad fecha inscripcion')"></p>
                                        <p class="m-t-40 font-20 font-bold text-primary"><i class="fa fa-clock-o fa-2x m-r-10 middle animated pulse infinite" style="margin-top:-2px;"></i> {{globals.Literal('Falta')}} {{globals.PostedDateBetween(data.fecha_inicio_reservas)}}</p>
                                        <p class="m-t-40">{{globals.Literal('Puedes reservar otras actividades')}}</p>
                                        <p class="m-b-40"><a href="https://mesdelareservabiosfera.es">https://mesdelareservabiosfera.es</a></p>
                                    </div>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="!data.proximamente">
                                <div class="ibox border">
                                    <div class="ibox-title"><i class="fa fa-lock"></i> {{globals.Literal('Actividad cerrada')}}</div>
                                    <div class="ibox-content">
                                        <p class="font-16" [innerHTML]="globals.Literal('Mensaje actividad fecha inscripcion')"></p>
                                        <p class="m-t-40">{{globals.Literal('Puedes reservar otras actividades')}}</p>
                                        <p class="m-b-40"><a href="https://mesdelareservabiosfera.es">https://mesdelareservabiosfera.es</a></p>
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf="data.disponible">
                            <ng-container *ngIf="!data.pasado && !data.cerrada_inscripcion">
                            
                                <div class="ibox border">
                                    <div class="ibox-title"><i class="fa fa-user"></i> {{globals.Literal('Introduce aquí tus datos')}}</div>
                                    <div class="ibox-content">
                                        <div class="row">
                                            <div class="col-sm-6 col-xs-12">
                                                <div class="form-group">
                                                <label class="text-primary">{{globals.Literal('Nombre')}} *</label>
                                                <input type="text" class="form-control" [(ngModel)]="data.nombre" maxlength="80" autocomplete="offff">
                                                </div>
                                            </div>
                                            <div class="col-sm-6 col-xs-12">
                                                <div class="form-group">
                                                <label class="text-primary">{{globals.Literal('Apellidos')}} *</label>
                                                <input type="text" class="form-control" [(ngModel)]="data.apellidos" maxlength="80" autocomplete="offff">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-6 col-xs-12">
                                                <div class="form-group">
                                                <label class="text-primary">{{globals.Literal('DNI')}} *</label>
                                                <input type="text" class="form-control" [(ngModel)]="data.dni" maxlength="15" autocomplete="offff"
                                                    placeholder="{{globals.idioma == 'pt' ? 'Exemplo: 12345678-9ZZ1' : ''}}">
                                                </div>
                                            </div>
                                            <div class="col-sm-6 col-xs-12">
                                                <div class="form-group">
                                                <label class="text-primary">{{globals.Literal('Sexo')}} *</label>
                                                <select class="form-control" [(ngModel)]="data.sexo" autocomplete="offff">
                                                    <option value="F">{{globals.Literal('Femenino')}}</option>
                                                    <option value="M">{{globals.Literal('Masculino')}}</option>
                                                </select>
                                                </div>
                                            </div>
                                        </div>
                                        <ng-container *ngIf="!globals.idioma || globals.idioma == 'es'">
                                            <div class="row">
                                                <div class="col-sm-6 col-xs-12">
                                                    <div class="form-group">
                                                    <label class="text-primary">{{globals.Literal('Código postal')}} *</label>
                                                    <input type="text" class="form-control" [(ngModel)]="data.codpostal" maxlength="5" autocomplete="offff"
                                                        placeholder="{{globals.idioma == 'pt' ? 'Exemplo: 2400-083' : ''}}"
                                                        (change)="DatosLocalidad()">
                                                    </div>
                                                </div>
                                                <div class="col-sm-6 col-xs-12 p-b-30 p-l-30 text-primary" *ngIf="data.localidad">
                                                    <div class="mobile-hidden-block p-t-30"></div>
                                                    <!-- <span [innerHTML]="data.localidad+', '+data.provincia" class="font-bold"></span> -->
                                                    <div id="lbllocalidad" class="font-bold"></div>
                                                </div>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="globals.idioma == 'pt'">
                                            <div class="row">
                                                <div class="col-sm-6 col-xs-12">
                                                    <div class="form-group">
                                                        <label class="text-primary">{{globals.Literal('Código postal')}} *</label>
                                                        <input type="text" class="form-control" [(ngModel)]="data.codpostal" maxlength="8" autocomplete="offff"
                                                        placeholder="Exemplo: 2400-083">
                                                    </div>
                                                </div>
                                                <div class="col-sm-6 col-xs-12">
                                                    <div class="form-group">
                                                        <label class="text-primary">{{globals.Literal('Localidad')}} *</label>
                                                        <input id="lbllocalidad" type="text" class="form-control" maxlength="50" autocomplete="offff">
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>
                                        <div class="row">
                                            <div class="col-sm-6 col-xs-12">
                                                <div class="form-group">
                                                <label class="text-primary">{{globals.Literal('Teléfono')}} *</label>
                                                <!-- <input type="tel" class="form-control" [(ngModel)]="data.telefono" maxlength="20" autocomplete="offff"> -->
                                                    <div class="input-group">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text">
                                                                <select class="form-group mb-0" [(ngModel)]="data.prefijo_telefono">
                                                                    <option value="+34">+34</option>
                                                                    <option value="+351">+351</option>
                                                                </select>
                                                                <img src="assets/img/flags/32/Spain.png" alt="" *ngIf="data.prefijo_telefono == '+34'" style="height:20px;margin-left:4px;vertical-align:middle;">
                                                                <img src="assets/img/flags/32/Portugal.png" alt="" *ngIf="data.prefijo_telefono == '+351'" style="height:20px;margin-left:4px;vertical-align:middle;">
                                                            </span>
                                                        </div>
                                                        <input type="tel" class="form-control" [(ngModel)]="data.telefono" maxlength="20" autocomplete="offff" 
                                                            (keyup)="KeyDownTel()"
                                                            (blur)="KeyDownTel()"
                                                            >
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-6 col-xs-12">
                                                <div class="form-group">
                                                <label class="text-primary">{{globals.Literal('Email')}} *</label>
                                                <input type="email" class="form-control" [(ngModel)]="data.email" maxlength="80" autocomplete="offff">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="ibox border caja-acompanante">
                                    <a class="ibox-title block">
                                        <i class="fa fa-user"></i> {{globals.Literal('Introduce datos acompanante')}} 1
                                        <div class="ibox-tools">
                                            <select class="form-control inline-block m-r-10" style="width:auto;margin-top:-8px" 
                                                [(ngModel)]="data.reservas[0].adulto"
                                                onclick="event.preventDefault();event.stopPropagation();return false;">
                                                <option value="">{{globals.Literal('Seleccione acompanante')}}</option>
                                                <option value="1">{{globals.Literal('Adulto')}}</option>
                                                <option value="0" *ngIf="data.reserva_ninos">{{globals.Literal('Niño')}}</option>
                                            </select>
                                            <!-- <a><i class="fa fa-chevron-up"></i></a> -->
                                        </div>
                                    </a>
                                    <div id="persona2" class="ibox-content {{!data.reservas[0] || data.reservas[0].adulto == '' ? 'hidden' : null}}">
                                        <div class="row">
                                            <div class="col-sm-6 col-xs-12">
                                                <div class="form-group">
                                                    <label class="text-primary">{{globals.Literal('Nombre')}} *</label>
                                                    <input id="txtnombre2" type="text" class="form-control" maxlength="80" autocomplete="offff" [(ngModel)]="data.reservas[0].nombre">
                                                </div>
                                            </div>
                                            <div class="col-sm-6 col-xs-12">
                                                <div class="form-group">
                                                    <label class="text-primary">{{globals.Literal('Apellidos')}} *</label>
                                                    <input id="txtapellidos2" type="text" class="form-control" maxlength="80" autocomplete="offff" [(ngModel)]="data.reservas[0].apellidos">
                                                </div>
                                            </div>
                                        </div>
                                        <ng-container *ngIf="data.reservas[0].adulto == '1'">
                                            <div class="row">
                                                <div class="col-sm-6 col-xs-12">
                                                    <div class="form-group">
                                                        <label class="text-primary">{{globals.Literal('DNI')}} *</label>
                                                        <input id="txtdni2" type="text" class="form-control" maxlength="15" autocomplete="offff" [(ngModel)]="data.reservas[0].dni">
                                                    </div>
                                                </div>
                                                <div class="col-sm-6 col-xs-12">
                                                    <div class="form-group">
                                                    <label class="text-primary">{{globals.Literal('Sexo')}} *</label>
                                                    <select id="cmbsexo2" class="form-control" autocomplete="offff" [(ngModel)]="data.reservas[0].sexo">
                                                        <option value="F">{{globals.Literal('Femenino')}}</option>
                                                        <option value="M">{{globals.Literal('Masculino')}}</option>
                                                    </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <ng-container *ngIf="!globals.idioma || globals.idioma == 'es'">
                                                <div class="row">
                                                    <div class="col-sm-6 col-xs-12">
                                                        <div class="form-group">
                                                            <label class="text-primary">{{globals.Literal('Código postal')}}</label>
                                                            <input id="txtcodpostal2" type="text" class="form-control" maxlength="5" autocomplete="offff"
                                                                (change)="DatosLocalidad($event, 'lbllocalidad2')" [(ngModel)]="data.reservas[0].codpostal">
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-6 col-xs-12 p-b-30 p-l-30 text-primary">
                                                        <div class="mobile-hidden-block p-t-30"></div>
                                                        <div id="lbllocalidad2" class="font-bold"></div>
                                                    </div>
                                                </div>
                                            </ng-container>
                                            <ng-container *ngIf="globals.idioma == 'pt'">
                                                <div class="row">
                                                    <div class="col-sm-6 col-xs-12">
                                                        <div class="form-group">
                                                            <label class="text-primary">{{globals.Literal('Código postal')}}</label>
                                                            <input id="txtcodpostal2" type="text" class="form-control" maxlength="8" autocomplete="offff"
                                                            placeholder="Exemplo: 2400-083" [(ngModel)]="data.reservas[0].codpostal">
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-6 col-xs-12">
                                                        <div class="form-group">
                                                            <label class="text-primary">{{globals.Literal('Localidad')}}</label>
                                                            <!-- <select id="lbllocalidad2" class="form-control chosen-select">
                                                                <option value="" selected>Sin seleccionar</option>
                                                                <option *ngFor="let item of localidades" [value]="item.localidad">{{item.provincia}} - {{item.localidad}}</option>
                                                            </select> -->
                                                            <input id="lbllocalidad2" type="text" class="form-control" maxlength="50" autocomplete="offff">
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-container>
                                            <div class="row">
                                                <div class="col-sm-6 col-xs-12">
                                                    <div class="form-group">
                                                        <label class="text-primary">{{globals.Literal('Teléfono')}}</label>
                                                        <input id="txttelefono2" type="tel" class="form-control" maxlength="20" autocomplete="offff" 
                                                        [(ngModel)]="data.reservas[0].telefono" 
                                                        (keyup)="KeyDownTel(data.reservas[0])"
                                                        (blur)="KeyDownTel(data.reservas[0])">
                                                    </div>
                                                </div>
                                                <div class="col-sm-6 col-xs-12">
                                                    <div class="form-group">
                                                        <label class="text-primary">{{globals.Literal('Email')}}</label>
                                                        <input id="txtemail2" type="email" class="form-control" maxlength="80" autocomplete="offff" [(ngModel)]="data.reservas[0].email">
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                                
                                <div class="ibox border caja-acompanante">
                                    <a class="ibox-title block">
                                        <i class="fa fa-user"></i> {{globals.Literal('Introduce datos acompanante')}} 2
                                        <div class="ibox-tools">
                                            <select class="form-control inline-block m-r-10" style="width:auto;margin-top:-8px" 
                                                [(ngModel)]="data.reservas[1].adulto"
                                                onclick="event.preventDefault();event.stopPropagation();return false;">
                                                <option value="">{{globals.Literal('Seleccione acompanante')}}</option>
                                                <option value="1">{{globals.Literal('Adulto')}}</option>
                                                <option value="0" *ngIf="data.reserva_ninos">{{globals.Literal('Niño')}}</option>
                                            </select>
                                            <!-- <a><i class="fa fa-chevron-up"></i></a> -->
                                        </div>
                                    </a>
                                    <div id="persona3" class="ibox-content  {{!data.reservas[1] || data.reservas[1].adulto == '' ? 'hidden' : null}}">
                                        <div class="row">
                                            <div class="col-sm-6 col-xs-12">
                                                <div class="form-group">
                                                    <label class="text-primary">{{globals.Literal('Nombre')}} *</label>
                                                    <input id="txtnombre3" type="text" class="form-control" maxlength="80" autocomplete="offff" [(ngModel)]="data.reservas[1].nombre">
                                                </div>
                                            </div>
                                            <div class="col-sm-6 col-xs-12">
                                                <div class="form-group">
                                                    <label class="text-primary">{{globals.Literal('Apellidos')}} *</label>
                                                    <input id="txtapellidos3" type="text" class="form-control" maxlength="80" autocomplete="offff" [(ngModel)]="data.reservas[1].apellidos">
                                                </div>
                                            </div>
                                        </div>
                                        <ng-container *ngIf="data.reservas[1].adulto == '1'">
                                            <div class="row">
                                                <div class="col-sm-6 col-xs-12">
                                                    <div class="form-group">
                                                    <label class="text-primary">{{globals.Literal('DNI')}} *</label>
                                                    <input id="txtdni3" type="text" class="form-control" maxlength="15" autocomplete="offff" [(ngModel)]="data.reservas[1].dni">
                                                    </div>
                                                </div>
                                                <div class="col-sm-6 col-xs-12">
                                                    <div class="form-group">
                                                    <label class="text-primary">{{globals.Literal('Sexo')}} *</label>
                                                    <select id="cmbsexo3" class="form-control" autocomplete="offff" [(ngModel)]="data.reservas[1].sexo">
                                                        <option value="F">{{globals.Literal('Femenino')}}</option>
                                                        <option value="M">{{globals.Literal('Masculino')}}</option>
                                                    </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <ng-container *ngIf="!globals.idioma || globals.idioma == 'es'">
                                                <div class="row">
                                                    <div class="col-sm-6 col-xs-12">
                                                        <div class="form-group">
                                                            <label class="text-primary">{{globals.Literal('Código postal')}}</label>
                                                            <input id="txtcodpostal3" type="text" class="form-control" maxlength="8" autocomplete="offff"
                                                                (change)="DatosLocalidad($event, 'lbllocalidad3')" [(ngModel)]="data.reservas[1].codpostal">
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-6 col-xs-12 p-b-30 p-l-30 text-primary">
                                                        <div class="mobile-hidden-block p-t-30"></div>
                                                        <div id="lbllocalidad3" class="font-bold"></div>
                                                    </div>
                                                </div>
                                            </ng-container>
                                            <ng-container *ngIf="globals.idioma == 'pt'">
                                                <div class="row">
                                                    <div class="col-sm-6 col-xs-12">
                                                        <div class="form-group">
                                                            <label class="text-primary">{{globals.Literal('Código postal')}}</label>
                                                            <input id="txtcodpostal3" type="text" class="form-control" maxlength="8" autocomplete="offff"
                                                                placeholder="Exemplo: 2400-083" [(ngModel)]="data.reservas[1].codpostal">
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-6 col-xs-12">
                                                        <div class="form-group">
                                                            <label class="text-primary">{{globals.Literal('Localidad')}}</label>
                                                            <!-- <select id="lbllocalidad3" class="form-control chosen-select">
                                                                <option value="" selected>Sin seleccionar</option>
                                                                <option *ngFor="let item of localidades" [value]="item.localidad">{{item.provincia}} - {{item.localidad}}</option>
                                                            </select> -->
                                                            <input id="lbllocalidad3" type="text" class="form-control" maxlength="50" autocomplete="offff">
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-container>
                                            <div class="row">
                                                <div class="col-sm-6 col-xs-12">
                                                    <div class="form-group">
                                                        <label class="text-primary">{{globals.Literal('Teléfono')}}</label>
                                                        <input id="txttelefono3" type="tel" class="form-control" maxlength="20" autocomplete="offff" 
                                                        [(ngModel)]="data.reservas[1].telefono" 
                                                        (keyup)="KeyDownTel(data.reservas[1])"
                                                        (blur)="KeyDownTel(data.reservas[1])">
                                                    </div>
                                                </div>
                                                <div class="col-sm-6 col-xs-12">
                                                    <div class="form-group">
                                                        <label class="text-primary">{{globals.Literal('Email')}}</label>
                                                        <input id="txtemail3" type="email" class="form-control" maxlength="80" autocomplete="offff" [(ngModel)]="data.reservas[1].email">
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                                
                                <div class="ibox border caja-acompanante">
                                    <a class="ibox-title block">
                                        <i class="fa fa-user"></i> {{globals.Literal('Introduce datos acompanante')}} 3
                                        <div class="ibox-tools">
                                            <select class="form-control inline-block m-r-10" style="width:auto;margin-top:-8px" 
                                                [(ngModel)]="data.reservas[2].adulto"
                                                onclick="event.preventDefault();event.stopPropagation();return false;">
                                                <option value="">{{globals.Literal('Seleccione acompanante')}}</option>
                                                <option value="1">{{globals.Literal('Adulto')}}</option>
                                                <option value="0" *ngIf="data.reserva_ninos">{{globals.Literal('Niño')}}</option>
                                            </select>
                                            <!-- <a><i class="fa fa-chevron-up"></i></a> -->
                                        </div>
                                    </a>
                                    <div id="persona4" class="ibox-content {{!data.reservas[2] || data.reservas[2].adulto == '' ? 'hidden' : null}}">
                                        <div class="row">
                                            <div class="col-sm-6 col-xs-12">
                                                <div class="form-group">
                                                    <label class="text-primary">{{globals.Literal('Nombre')}} *</label>
                                                    <input id="txtnombre4" type="text" class="form-control" maxlength="80" autocomplete="offff" [(ngModel)]="data.reservas[2].nombre">
                                                </div>
                                            </div>
                                            <div class="col-sm-6 col-xs-12">
                                                <div class="form-group">
                                                    <label class="text-primary">{{globals.Literal('Apellidos')}} *</label>
                                                    <input id="txtapellidos4" type="text" class="form-control" maxlength="80" autocomplete="offff" [(ngModel)]="data.reservas[2].apellidos">
                                                </div>
                                            </div>
                                        </div>
                                        <ng-container *ngIf="data.reservas[2].adulto == '1'">
                                            <div class="row">
                                                <div class="col-sm-6 col-xs-12">
                                                    <div class="form-group">
                                                    <label class="text-primary">{{globals.Literal('DNI')}} *</label>
                                                    <input id="txtdni4" type="text" class="form-control" maxlength="15" autocomplete="offff" [(ngModel)]="data.reservas[2].dni">
                                                    </div>
                                                </div>
                                                <div class="col-sm-6 col-xs-12">
                                                    <div class="form-group">
                                                    <label class="text-primary">{{globals.Literal('Sexo')}} *</label>
                                                    <select id="cmbsexo4" class="form-control" autocomplete="offff" [(ngModel)]="data.reservas[2].sexo">
                                                        <option value="F">{{globals.Literal('Femenino')}}</option>
                                                        <option value="M">{{globals.Literal('Masculino')}}</option>
                                                    </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <ng-container *ngIf="!globals.idioma || globals.idioma == 'es'">
                                                <div class="row">
                                                    <div class="col-sm-6 col-xs-12">
                                                        <div class="form-group">
                                                            <label class="text-primary">{{globals.Literal('Código postal')}}</label>
                                                            <input id="txtcodpostal4" type="text" class="form-control" maxlength="8" autocomplete="offff"
                                                                (change)="DatosLocalidad($event, 'lbllocalidad4')" [(ngModel)]="data.reservas[2].codpostal">
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-6 col-xs-12 p-b-30 p-l-30 text-primary">
                                                        <div class="mobile-hidden-block p-t-30"></div>
                                                        <div id="lbllocalidad4" class="font-bold"></div>
                                                    </div>
                                                </div>
                                            </ng-container>
                                            <ng-container *ngIf="globals.idioma == 'pt'">
                                                <div class="row">
                                                    <div class="col-sm-6 col-xs-12">
                                                        <div class="form-group">
                                                            <label class="text-primary">{{globals.Literal('Código postal')}}</label>
                                                            <input id="txtcodpostal4" type="text" class="form-control" maxlength="8" autocomplete="offff"
                                                                placeholder="Exemplo: 2400-083" [(ngModel)]="data.reservas[2].codpostal">
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-6 col-xs-12">
                                                        <div class="form-group">
                                                            <label class="text-primary">{{globals.Literal('Localidad')}}</label>
                                                            <!-- <select id="lbllocalidad4" class="form-control chosen-select">
                                                                <option value="" selected>Sin seleccionar</option>
                                                                <option *ngFor="let item of localidades" [value]="item.localidad">{{item.provincia}} - {{item.localidad}}</option>
                                                            </select> -->
                                                            <input id="lbllocalidad4" type="text" class="form-control" maxlength="50" autocomplete="offff">
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-container>
                                            <div class="row">
                                                <div class="col-sm-6 col-xs-12">
                                                    <div class="form-group">
                                                        <label class="text-primary">{{globals.Literal('Teléfono')}}</label>
                                                        <input id="txttelefono4" type="tel" class="form-control" maxlength="20" autocomplete="offff" 
                                                        [(ngModel)]="data.reservas[2].telefono" 
                                                        (keyup)="KeyDownTel(data.reservas[2])"
                                                        (blur)="KeyDownTel(data.reservas[2])">
                                                    </div>
                                                </div>
                                                <div class="col-sm-6 col-xs-12">
                                                    <div class="form-group">
                                                        <label class="text-primary">{{globals.Literal('Email')}}</label>
                                                        <input id="txtemail4" type="email" class="form-control" maxlength="80" autocomplete="offff" [(ngModel)]="data.reservas[2].email">
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                                
                                <div class="ibox border" *ngIf="data.turnos">
                                    <div class="ibox-title block">
                                        <i class="fa fa-clock-o"></i> Turno *
                                    </div>
                                    <div class="ibox-content">
                                        <select class="form-control" [(ngModel)]="data.turno" style="color: var(--color-secondary) !important;">
                                            <option [value]="item" *ngFor="let item of data.turnos.split(',')">{{item}}</option>
                                        </select>
                                    </div>
                                </div>
            
                                <br>
                                <a class="alert alert-info block" href="/faqs" target="_blank">
                                    <span [innerHTML]="globals.Literal('Consulta faqs')"></span><br>
                                    <span class="text-primary" [innerHTML]="globals.Literal('Recortadorio')"></span>
                                </a>
                                <div class="form-group margin-0 checkbox">
                                    <input type="checkbox" id="chkConsentimientoFoto" [(ngModel)]="data.consentimiento_fotos">
                                    <label class="block" for="chkConsentimientoFoto">{{globals.Literal('Consentimiento fotos')}} <a href="/politica-de-privacidad#autorizacion-imagen" target="_blank">{{globals.Literal('política de privacidad')}}</a></label>
                                </div>
                                <div class="form-group margin-0 checkbox">
                                    <input type="checkbox" id="chkConsentimientoComunicaciones" [(ngModel)]="data.consentimiento_comunicaciones">
                                    <label class="block" for="chkConsentimientoComunicaciones">{{globals.Literal('Consentimiento comunicaciones')}} <a href="/politica-de-privacidad#autorizacion-comunicaciones" target="_blank">{{globals.Literal('política de privacidad')}}</a></label>
                                </div>
                                <div class="form-group margin-0 checkbox">
                                    <input type="checkbox" id="chkAceptarPolitica" [(ngModel)]="data.aceptar_politica">
                                    <label class="block" for="chkAceptarPolitica">{{globals.Literal('Acepto y he leido')}} <a href="/politica-de-privacidad" target="_blank">{{globals.Literal('política de privacidad')}}</a> {{globals.Literal('y')}} <a href="/politica-de-privacidad" target="_blank">{{globals.Literal('aviso legal')}}</a></label>
                                </div>
            
                                <div class="text-center m-t-20 m-b-40">
                                    <a id="btnReservar" class="btn btn-primary font-18" style="min-width: 200px;" (click)="Reservar()">{{globals.Literal('Reservar')}}</a>
                                </div>
                                
                            </ng-container>
                            <ng-container *ngIf="data.pasado || data.cerrada_inscripcion">
                                
                                <div class="ibox border">
                                    <div class="ibox-title"><i class="fa fa-lock"></i> {{globals.Literal('Actividad cerrada')}}</div>
                                    <div class="ibox-content">
                                        <p class="font-16" [innerHTML]="globals.Literal('Mensaje actividad cerrada')"></p>
                                        <p class="m-t-40">{{globals.Literal('Puedes reservar otras actividades')}}</p>
                                        <p class="m-b-40"><a href="https://mesdelareservabiosfera.es">https://mesdelareservabiosfera.es</a></p>
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>
                    <!-- <div class="text-center m-t-20 m-b-40">
                        <a class="btn btn-default font-18" style="min-width: 200px;">{{globals.Literal('Actividad cerrada')}}</a>
                    </div> -->

                  </div>
              </div>
          </div>
        </div>
        <div class="wrapper wrapper-content animated fadeInDown" *ngIf="reservado">
            <div class="container font-20">
                <h1 class="m-t-20 font-bold">{{globals.Literal('Gracias por tu reserva')}}</h1>
                <p class="m-t-20" [innerHTML]="globals.Literal('Mensaje gracias')"></p>
                
                <p class="m-t-40">{{globals.Literal('Puedes reservar otras actividades')}}</p>
                <p class="m-b-40"><a href="https://mesdelareservabiosfera.es">https://mesdelareservabiosfera.es</a></p>
            </div>
        </div>
    
      </div>
    </div>
</div>