<div id="wrapper">
    <app-menu></app-menu>
    
    <div id="page-wrapper" class="gray-bg dashbard-1">
      <app-header></app-header>
  
      <div class="row wrapper border-bottom white-bg page-heading">
        <div class="col-md-8 col-sm-6 col-xs-12">
            <h2>
              <i class="icofont-calendar"></i>
              {{title}}
            </h2>
            <ol class="breadcrumb m-l-10">
                <li class="breadcrumb-item">
                    <a routerLink="/dashboard">Inicio</a>
                </li>
                <li class="breadcrumb-item">
                    <a routerLink="/actividades/admin/reservas">Reservas</a>
                </li>
                <li class="breadcrumb-item active">
                    <strong>{{title}}</strong>
                </li>
            </ol>
        </div>
        <div class="col-md-4 col-sm-6 col-xs-12 col-botones">
            <a class="btn btn-default" (click)="Cancelar()"><i class="fa fa-arrow-left"></i> Volver</a>
        </div>
      </div>
      <div class="animated fadeInRight" *ngIf="data && data.id != ''">

        <div class="row m-t-20">
            <div class="col-xl-6 col-lg-8 col-md-8 col-sm-12 col-centered">
                <div class="actividad-card-admin padding {{data.cerrada_inscripcion ? 'actividad-cerrada' : null}}"
                    [style.background-image]="data.imagen ? 'url('+data.imagen+')' : null"
                    (click)="IrActividad()">
                    <div class="fecha">
                        <div>{{data.fecha_mes}} {{data.fecha_dia}}</div>
                    </div>
                    <div class="actividad-card-admin-body">
                        <h2 class="titulo"><i class="fa fa-lock" *ngIf="data.cerrada_inscripcion"></i> {{data.titulo}}</h2>
                        <div>{{data.categoria}}</div>
                        <div><i class="icofont-google-map text-white"></i> {{data.ubicacion}}</div>
                        <div>Aforo: {{data.aforo}} Pers.</div>
                        <div class="resumen confirmados">
                        <span>{{data.plazas_ocupadas}}</span> confirmadas
                        </div>
                        <div class="resumen" *ngIf="data.plazas_pendientes && data.plazas_pendientes > 0">
                        <span>{{data.plazas_pendientes}}</span> solicitudes
                        </div>
                    </div>
                </div>
                <div class="progress" *ngIf="data.plazas_ocupadas">
                    <div class="progress-bar" style="width:{{data.plazas_ocupadas*100/data.aforo}}%" role="progressbar" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <div><b>{{data.plazas_ocupadas}}</b> de {{data.aforo}} personas</div>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-4 col-sm-12 col-botones col-centered" style="flex-direction:column;justify-content:center;">
                <a class="btn btn-default btn-block" (click)="CerrarAbrirActividad()" *ngIf="!data.cerrada_inscripcion">
                    <i class="fa fa-lock"></i>
                    Cerrar inscripciones
                </a>
                <a class="btn btn-default btn-block" (click)="CerrarAbrirActividad()" *ngIf="data.cerrada_inscripcion">
                    <i class="fa fa-unlock"></i>
                    Abrir inscripciones
                </a>
                <hr>
                <a class="btn btn-default btn-block" (click)="Imprimir('En Espera')">
                    <i class="fa fa-print"></i>
                    Imprimir lista <span class="text-warning">en espera</span>
                </a>
                <a class="btn btn-default btn-block" (click)="Excel('En Espera')">
                    <i class="fa fa-file-excel-o"></i>
                    Excel lista <span class="text-warning">en espera</span>
                </a>
                <hr>
                <a class="btn btn-default btn-block" (click)="Imprimir()">
                    <i class="fa fa-print"></i>
                    Imprimir lista <span class="text-success">confirmadas</span>
                </a>
                <a class="btn btn-default btn-block" (click)="Excel()">
                    <i class="fa fa-file-excel-o"></i>
                    Excel lista <span class="text-success">confirmadas</span>
                </a>
            </div>
        </div>
        <div class="row m-t-10">
            <div class="col-lg-4 col-md-4 col-sm-12 hidden">
                <div class="panel panel-danger">
                    <a class="panel-heading" href="#panel-pendientes" data-toggle="collapse">
                        <i class="fa fa-exclamation-triangle"></i> Pendiente <span *ngIf="pendientes && pendientes.length">({{pendientes.length}})</span>
                        <a class="btn btn-oscuro m-l-15" (click)="EmailTodoPendientes($event)"><i class="fa fa-envelope-o"></i> Email a la lista</a>
                        <div class="ibox-tools"><a><i class="fa fa-chevron-up"></i></a></div>
                    </a>
                    <div id="panel-pendientes" class="panel-body collapse show">
                        <div class="feed-activity-list">
                            <div class="feed-element cursor" *ngIf="!pendientes || !pendientes.length">
                                <div class="media-body">
                                    <strong>No hay reservas pendientes</strong>
                                </div>
                            </div>
                            <div class="feed-element cursor" *ngFor="let item of pendientes" (click)="Editar(item)">
                                <div class="pull-right">
                                    <a class="btn btn-sm btn-danger m-r-10" (click)="BorrarReserva($event, item)"><i class="fa fa-close text-white"></i></a>
                                    <a class="btn btn-sm btn-default"><i class="fa fa-pencil"></i></a>
                                </div>
                                <div class="media-body">
                                    <strong class="text-secondary">{{item.referencia}}</strong><br>
                                    <strong class="text-primary" *ngIf="item.colaborador"><i class="icofont-tasks"></i> {{item.colaborador}}<br></strong>
                                    <strong>{{item.nombre}} {{item.apellidos}}</strong> ha solicitado reserva.<br>
                                    <div>{{item.localidad}}, {{item.provincia}}</div>
                                    <div><i class="fa fa-envelope"></i> {{item.email}}</div>
                                    <div><i class="fa fa-phone"></i> {{item.telefono}}</div>
                                    <div *ngIf="item.turno">Turno: {{item.turno}}</div>
                                    <small class="text-muted">Hace {{globals.PostedDate(item.alta)}}</small>
                                </div>
                                <div class="m-l-60 m-t-5 font-12" *ngIf="item.personas && item.personas.length">
                                    <div>Acompa&ntilde;antes ({{item.personas.length}})</div>
                                    <div class="border-top-light m-t-5 p-t-5 p-b-5" *ngFor="let elem of item.personas">
                                        <strong>{{elem.nombre}} {{elem.apellidos}}</strong>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12">
                <div class="panel panel-warning">
                    <a class="panel-heading" href="#panel-enespera" data-toggle="collapse">
                        <i class="fa fa-clock-o"></i> En espera <span *ngIf="enespera && enespera.length">({{enespera.length}})</span>
                        <a class="btn btn-oscuro m-l-15" (click)="EmailTodosEnEspera($event)"><i class="fa fa-envelope-o"></i> Email a la lista</a>
                        <div class="ibox-tools"><a><i class="fa fa-chevron-up"></i></a></div>
                    </a>
                    <div id="panel-enespera" class="panel-body collapse show">
                        <div class="feed-activity-list">
                            <div class="feed-element cursor" *ngIf="!enespera || !enespera.length">
                                <div class="media-body">
                                    <strong>No hay reservas en espera</strong>
                                </div>
                            </div>
                            <ng-container *ngFor="let item of enespera">
                                <div class="feed-element cursor" (click)="Editar(item)">
                                    <div class="pull-right">
                                        <a class="btn btn-sm btn-danger m-r-10" (click)="BorrarReserva($event, item)"><i class="fa fa-close text-white"></i></a>
                                        <a class="btn btn-sm btn-default"><i class="fa fa-pencil"></i></a>
                                    </div>
                                    <div class="media-body">
                                        <strong class="text-secondary">{{item.referencia}}</strong><br>
                                        <strong class="text-primary" *ngIf="item.colaborador"><i class="icofont-tasks"></i> {{item.colaborador}}<br></strong>
                                        <strong>{{item.nombre}} {{item.apellidos}}</strong><br>
                                        <div>{{item.localidad}}, {{item.provincia}}</div>
                                        <div><i class="fa fa-envelope"></i> {{item.email}}</div>
                                        <div><i class="fa fa-phone"></i> {{item.telefono}}</div>
                                        <div *ngIf="item.turno">Turno: {{item.turno}}</div>
                                        <small class="text-muted">Hace {{globals.PostedDate(item.alta)}}</small>
                                    </div>
                                    <div class="m-l-60 m-t-5 font-12" *ngIf="item.personas && item.personas.length">
                                        <div>Acompa&ntilde;antes ({{item.personas.length}})</div>
                                        <div class="border-top-light m-t-5 p-t-5 p-b-5" *ngFor="let elem of item.personas">
                                            <strong>{{elem.nombre}} {{elem.apellidos}}</strong>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12">
                <div class="panel panel-success">
                    <a class="panel-heading" href="#panel-confirmadas" data-toggle="collapse">
                        <i class="fa fa-calendar"></i> Confirmadas <span *ngIf="data.plazas_ocupadas">({{data.plazas_ocupadas}})</span>
                        <br>
                        <a class="btn btn-oscuro m-l-15 m-t-10" (click)="EmailTodosConfirmados($event)"><i class="fa fa-envelope-o"></i> Email a la lista</a>
                        <br>
                        <a class="btn btn-oscuro m-l-15 m-t-10" (click)="EmailCambioHorario($event)"><i class="fa fa-clock-o"></i> Email cambio de horario</a>
                        <div class="ibox-tools"><a><i class="fa fa-chevron-up"></i></a></div>
                    </a>
                    <div id="panel-confirmadas" class="panel-body collapse show">
                        <div class="feed-activity-list">
                            <div class="feed-element cursor" *ngIf="!reservas || !reservas.length">
                                <div class="media-body">
                                    <strong>No hay reservas confirmadas</strong>
                                </div>
                            </div>
                            <ng-container *ngFor="let item of reservas">
                                <div class="feed-element cursor" (click)="Editar(item)">
                                    <div class="pull-right">
                                        <img [src]="'https://chart.apis.google.com/chart?cht=qr&chs=50x50&chld=M%7C0&chl='+item.referencia" class="m-r-10" style="height:50px;vertical-align:top;">
                                        <a class="btn btn-sm btn-danger m-r-10" (click)="BorrarReserva($event, item)"><i class="fa fa-close text-white"></i></a>
                                        <a class="btn btn-sm btn-default"><i class="fa fa-pencil"></i></a>
                                    </div>
                                    <div class="media-body">
                                        <strong class="text-secondary">{{item.referencia}} <strong class="text-success m-l-10" *ngIf="item.asistencia"><i class="fa fa-check"></i> Asistencia validada </strong></strong><br>
                                        <strong class="text-primary" *ngIf="item.colaborador"><i class="icofont-tasks"></i> {{item.colaborador}}<br></strong>
                                        <strong>{{item.nombre}} {{item.apellidos}}</strong><br>
                                        <div>{{item.localidad}}, {{item.provincia}}</div>
                                        <div><i class="fa fa-envelope"></i> {{item.email}}</div>
                                        <div><i class="fa fa-phone"></i> {{item.telefono}}</div>
                                        <div *ngIf="item.turno">Turno: {{item.turno}}</div>
                                        <small class="text-muted">Hace {{globals.PostedDate(item.alta)}}</small>
                                    </div>
                                    <div class="m-l-60 m-t-5 font-12" *ngIf="item.personas && item.personas.length">
                                        <div>Acompa&ntilde;antes ({{item.personas.length}})</div>
                                        <div class="border-top-light m-t-5 p-t-5 p-b-5" *ngFor="let elem of item.personas">
                                            <strong>{{elem.nombre}} {{elem.apellidos}}</strong>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>
          
      </div>
      
      <div class="col-botones m-t-10">
        <a class="btn btn-default" (click)="Cancelar()"><i class="fa fa-arrow-left"></i> Volver</a>
      </div>
  
      <app-footer></app-footer>
    </div>
  
</div>